import consumer from "./consumer"

async function disconnectActionCable(sub) {
  sub.unsubscribe();
  consumer.pep = null;
  return {};
}

async function connectActionCable() {
  return consumer.pep = consumer.subscriptions.create({
    channel: "PepChannel",
    pep_id: $("#pep-edit-container").data('pep-id')
  }, {
    connected: function() {
    },
    received: function(data) {
      updateView(data);
    },
    disconnected: function(data) {
    }
  });
}

async function verifyConnection() {
  const identifier = JSON.parse(consumer.pep.identifier);
  if (identifier.pep_id !== $("#pep-edit-container").data('pep-id')) {
    await disconnectActionCable(consumer.pep)
    await connectActionCable()
  } else {
  }
}

$(document).on('turbo:load', function() {
  if (document.getElementById("pep-edit-container")) {
    if (consumer.pep) {
      verifyConnection();
    } else {
      connectActionCable();
    }
  } else if (consumer.pep) {
    disconnectActionCable(consumer.pep)
  }
});

function updateView(data) {
  if (data.pep_id !== $("#pep-edit-container").data('pep-id')) {
    return;
  }

  switch(data.kind) {
    case 'pep_criterion':
      updatePepCriterionView(data);
      break;
    case 'pep_category_goal':
      updatePepCategoryGoal(data);
      break;
    case 'pep_narrative':
      updatePepNarrativeView(data);
      break;
  }
}

function updatePepCriterionView(data) {
  const $checkbox = $(`#criteriaCheck${data.id}`)
  const $score = $('#pep-score-id-'+ data.question_id);

  if (data.value == 'true') {
    $checkbox.prop( "checked", true );
  }  else if (data.value == 'false')  {
    $checkbox.prop( "checked", false );
  }

  if (data.question_score === 0) {
    $score.text(locale_translation.pep_question.incomplete);
    $score.closest('.pep-score').removeClass('pep-state-complete').addClass('pep-state-incomplete');
  } else {
    $score.closest('.pep-score').removeClass('pep-state-incomplete').addClass('pep-state-complete');
    $score.text(locale_translation.pep_question.level + " " + data.question_score);
  }
}

function updatePepCategoryGoal(data) {
  const $textarea = $(`#CatGoal-Cat${data.pep_category_id}-Goal${data.id}-Question${data.pep_question_id}`);
  $textarea.val(data.value);
  const editor = document.querySelector(`[input='CatGoal-Cat${data.pep_category_id}-Goal${data.id}-Question${data.pep_question_id}']`).editor
  editor.loadHTML(data.value);
}

function updatePepNarrativeView(data) {
  const $checkbox = $(`#narrativeCheck${data.id}`)

  if (data.value == 'true') {
    $checkbox.prop( "checked", true );
  }  else if (data.value == 'false')  {
    $checkbox.prop( "checked", false );
  }
}
